<template>
  <div>
    <el-row>
      <el-col
        :span="showLabel && !isDefalutPosq ? 8 : data.description ? 20 : 24"
      >
        {{ data.label }}
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
    </el-row>
    <span v-if="isActive" class="setting-icon" @click="openSettings">
      <i class="el-icon-s-tools" />
    </span>
    <div v-html="data.content" :style="getStyle"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchGlobalVariableById } from "@/repo/globalVariables";
export default {
  name: "HtmlView",
  props: ["field", "data", "isActive", "isFreeStyle"],
  data() {
    return {
      showLabel: false,
    };
  },
  mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    getStyle() {
      if (this.isFreeStyle) {
        return (
          `height:${this.data.height}px;width:${this.data.width}px;` +
          this.getElementStyle
        );
      }
      return this.getElementStyle;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
      }
      return borderStyle;
    },
  },
  methods: {
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      // await this.$store.dispatch(
      //   "globalVariables/fetchGlobalVariableById",
      //   params
      // );
      let globalVariable = await fetchGlobalVariableById(params);
      if (globalVariable) {
        if (globalVariable.input_type == "HTML_CONTENT") {
          // this.isList = true;
          this.data.content = globalVariable.value;
          // this.data.is_list = true;
        } else {
          // this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    },
  },
};
</script>

<style lang="scss">
.resposive-font {
  font-size: 1.2vw;
}
</style>
